import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import SEO from "@components/seo";
import Table from "react-bootstrap/Table";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import { normalizedData } from "@utils/functions";

const Division = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title='Division & Depts' pathname='/' />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title='Division & Depts'
            />
            <Container>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    backgroundColor: "#470880",
                                    color: "#fff",
                                }}
                            >
                                SL No
                            </th>
                            <th
                                style={{
                                    backgroundColor: "#470880",
                                    color: "#fff",
                                }}
                                colSpan={2}
                            >
                                Division
                            </th>
                            <th
                                style={{
                                    backgroundColor: "#470880",
                                    color: "#fff",
                                }}
                                colSpan={2}
                            >
                                Department
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: "middle" }}>1</td>
                            <td
                                colSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "20px",
                                }}
                            >
                                Operation
                            </td>
                            <td>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Claims
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    In-house Training
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td
                                colSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "20px",
                                }}
                            >
                                Underwriting & Re-Insurance
                            </td>
                            <td>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Risk Management
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Specialized Underwriting
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Re-insurance
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Policy Issuing
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "middle" }}>3</td>
                            <td
                                colSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "20px",
                                }}
                            >
                                Marketing
                            </td>
                            <td>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Branch Co-ordination & Dev
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    New Product & Services
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Marketing Services & Health Plan
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "middle" }}>4</td>
                            <td
                                colSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "20px",
                                }}
                            >
                                Finance & Accounts
                            </td>
                            <td>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Finance
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Accounts
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Share & Board Affairs
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "middle" }}>5</td>
                            <td
                                colSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "20px",
                                }}
                            >
                                HR & Administration
                            </td>
                            <td>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Human Resources
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Admin & General Service
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "middle" }}>6</td>
                            <td
                                colSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "20px",
                                }}
                            >
                                Information Technology
                            </td>
                            <td>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Hardware
                                </p>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Software
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "middle" }}>7</td>
                            <td
                                colSpan={2}
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: "20px",
                                }}
                            >
                                Internal Audit & Control
                            </td>
                            <td>
                                <p
                                    style={{
                                        color: "#000",
                                        padding: "5px",
                                    }}
                                >
                                    Internal Audit & Compliance
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </Layout>
    );
};
Division.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query DivisionQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default Division;
